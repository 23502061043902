
//=require ../vendor/imarcom/open-modal/locales/en.js

var locale = {
    "language" : "en",
    "caps_lock_on" : "Caps Lock key is active.",
    "download" : "Download",
    "checkout" : "Order online",
    "clean_base_site_url" : "/",
    "file_upload_max_qty_exceed" : "The simultaneously downloading files limit is <strong>%s</strong>.",
    "public_max_file_upload_size" : "50000000",
    "file_upload_type_invalid" : "Only '<strong>%s</strong>' files are accepted.",
    "public_file_upload_progress" : "Uploading...",
    "file_upload_max_size_exceed" : "The file '%s' exceed the permissible limit of %sMb.",
    "upload_file_error_uploading" : "An error occurred while uploading file(s):",
    "remaining_characters" : "%s remaining(s) character(s)",
    "auto_ajax_url" : "/ajax.do",
    "public_form_ajax_url" : "/public_form",
    "language" : "en",
    "phone" : "Phone",
    "email" : "Email",
    "password_meter" : "Password Strength",
    "password_level_0" : "You need a stronger password",
    "password_level_1" : "You can do better than that...",
    "password_level_2" : "Your password is a bit weak",
    "password_level_3" : "Good password, could be better",
    "password_level_4" : "Your password is good!",
    "password_level_5" : "Great password!",
    "error_unknown_append" : "An unknown error occured",
    "error_try_again_later" : "Try again later.",
    "session_expired_title" : "Your client session has expired.",
    "session_expired_text" : "<p>Your connection timeout in our secure area has expired. Your session has ended for reasons of safety and protection of your personal data. To continue, simply log back to our site.</p><p>Thank you for your understanding.</p>",
    "error_append" : "An error has occurred.",
    "close" : "Close",
    "select_country" : "Choose your country",
    "select_state" : "Choose your state",
    "select_province" : "Choose your province",
    "select_region" : "Choose your region",
    "select_county" : "Choose your county",
    "newsletter.subscription" : "Newsletter subscription",
    "newsletter.invalid_email" : "Oups! This email is invalid. Please enter a valid email.",
    "newsletter.invalid_domain" : "Oups! This domain use is invalid. Please use a valid domain.",
    "newsletter.please_confirm" : "<p class=\"page_title\">Please confirm!</p><hr><div class=\"content\"><p>Check your inbox, an email of confirmation will be sent shortly.</p><p>To complete your subscription, you must confirm your email address.</p></div>",
    "newsletter.subscribed" : "<p class=\"page_title\">Subscription confirmed!</p><hr><p class=\"content\">You will receive your first newsletter shortly.</p>",
    "newsletter.unknown_error" : "Oups! An error has occurred. Please try again.",
    "confirmation" : "Confirmation",
    "yes" : "Yes",
    "no" : "No",

    /**
     * Locale pour site actuel ICI
     */
};

