/**
 * 2015-04-14 - Jean-François Sanfaçon <jfsanfacon@imarcom.net>
 */
;(function($, undefined) {
	$.openModal.locale = {
		close_label: 'Close',
		error_title: 'Error',
		error_happend: 'An error has occurred.',
		error_unknow_happend: 'An unknown error occured.',
		error_try_again_later: 'Try again later.'
	};
}(jQuery));
